<template>
    <div class="comp">
        <div class="comp-container">
            <form @submit.prevent="submit" class="input-components">
                <h2> احراز هویت </h2>
                <PersonalInfo v-if="!noSee" :data.sync="data"/>
                <ShowPersonalInfo v-if="noSee" :data.sync="data" />
                <PhoneInfo :data.sync="data" />
                <BankInfo :data.sync="data" />
                <UploadPic v-if="!noSee" :data.sync="data" />
                <!-- v-if="state.userInfo.authenticationType !== 'AUTHORIZED'" -->
                <button :disabled="disabled" class="submit">{{disabled ? disabled :' ثبت و ارسال اطلاعات '}}</button>
            </form>
            <div v-if="!noSee" class="guide">
                <img src="../../../assets/accountGuide.svg" alt="">
                <div class="guide-texts">
                    <div class="text-container">
                        <h3> اطلاعات شخصی </h3>
                        <span class="guide-body"> اطلاعات شخصی باید بر اساس مدارک شناسایی تکمیل شوند در غیر این صورت احراز هویت با خطا مواجه خواهد شد </span>
                    </div>
                    <div class="text-container">
                        <h3> اطلاعات تماس </h3>
                    </div>
                    <div class="text-container">
                        <h3> اطلاعات بانکی </h3>
                        <span class="guide-body"> حداقل یک حساب بانکی معرفی کنید </span>
                    </div>
                    <div class="text-container">
                        <h3> آپلود مدارک </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PersonalInfo from './PersnalInfo.vue'
import ShowPersonalInfo from './ShowPersonalInfo.vue'
import PhoneInfo from './PhoneInfo.vue'
import BankInfo from './BankInfo.vue'
import UploadPic from './UploadPic.vue'
const inputLabel = {
        'firstName': 'نام',
        'lastName': 'نام خانوادگی',
        'email': 'ایمیل',
        'birthDay': 'تاریخ تولد',
        'mobileNumber': 'شماره موبایل',
        'telephone': 'شماره ثابت',
        'address': 'آدرس',
        'image': 'عکس',
}
    export default {
        name:'Account',
        components:{
            PersonalInfo,
            PhoneInfo,
            BankInfo,
            UploadPic,
            ShowPersonalInfo
        },
        data() {
            return {
                   data:{
                        authenticationType: '',
                        firstName:'',
                        lastName:'',
                        birthDay:'',
                        email: this.$cookies.get("username") || '',
                        nationalCode:'',
                        mobileNumber:'',
                        telephone: '',
                        address: '',
                        bankAccounts: [...this.state.userInfo.bankAccounts],
                        image: '',
                   },
                   blanckSpace:{
                        firstName:false,
                        lastName:false,
                        birthDay:false,
                        nationalCode:false,
                        mobileNumber:false,
                        telephone: false,
                        address: false,
                   },

            }
        },
        computed: {
            noSee() {
                return ['AUTHORIZED', "INFORMATION_CHANGED"].includes(this.state.userInfo.authenticationType)
            },
            disabled() {
                if(this.$route.name=='Validation'){
                    return false
                }
                for (let key in this.data) {
                    let items = ['firstName', 'lastName', 'email', 'nationalCode', 'birthDay', 'mobileNumber', 'telephone', 'bankAccounts', 'image','address']
                    if (items.includes(key)) {
                        if (key === 'bankAccounts') {
                            if (this.data.bankAccounts.length < 1) {
                                return 'اطلاعات بانکی اشتباه است'
                            }
                        } else if (!this.data[key]) {
                                return inputLabel[key] + ' اجباریست'
                        }
                    }
                }
                return false
            },
        },
        methods: {
            checkMobileChange(){
                let bol
                if(this.data.mobileNumber !== this.state.userInfo.mobileNumber || this.$route.name == 'Validation'){
                    bol=true
                }else{
                    bol=false
                }
                return bol
            },
            async submit() {
                this.state.loading = true;
                let form_data = new FormData();
                for (let key in this.data) {
                    if (key === 'image') {
                        if (typeof this.data.image !== 'string') {
                            console.log(this.data.image);
                            form_data.append("file", this.data.image);
                            form_data.append("document", this.data.image);
                        }
                    } else {
                        form_data.append(key, this.data[key]);
                    }
                }

                form_data.append("bankAccountsString", JSON.stringify(this.data.bankAccounts));

                    try {
                        let vm = this
                        const config = {
                            onUploadProgress: function (progressEvent) {
                                vm.state.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            }
                        }
                            const res = await this.$axios.post("/users/account", form_data, config)

                        this.state.userInfo.authenticationType = res.baseDTO.authenticationType
                        this.state.userInfo.email = res.baseDTO.email
                        this.state.userInfo.authorized = res.baseDTO.authenticationType === "AUTHORIZED"
                        this.state.userInfo.pending = res.baseDTO.authenticationType === "PENDING"
                        await this.$error('', 'اطلاعات با موفقیت ذخیره شد', 'success')
                        this.$getuserInfo(true)
                        if(this.checkMobileChange()){
                            this.$router.push({name:'FinoTech'});
                        }
                    } catch (e) {
                        if (e.response && e.response.data.message.includes("ShebaCode or CardNumber Is For Another User")) {
                            this.$error('', ' شماره شبا یا کارت قبلا وارد شده است.')
                            this.duplicateBank = e.response.data.message
                        }
                    }
            },
        },
        mounted() {
            this.data=this.state.userInfo
        },

    }
</script>

<style lang="scss" scoped>
.input-components{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 30%;
}
.guide{
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    width: 15%;
    position: sticky;
    top: 50px;
    .guide-texts{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span{
            font-size: clamp(10px,1vw,12px);
        }
    }
}
h2{
    font-size: clamp(16px,2vw,20px);
}
.comp-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    row-gap: 16px;
    align-items: flex-start;
    justify-content: space-around;
}
.comp{
    min-height: 92vh;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
@media only screen and(max-width:1200px){
    .input-components{
        width: 50%;
    }
}
@media only screen and(max-width:1000px){
    .comp-container{
        flex-direction: column;
    }
    .input-components{
        width: 95%;
    }
    .guide{
        width: 100%;
        order: -1;
    }
}
</style>