<template>
    <div class="personal-info">
        <span> آپلود مدارک </span>
        <div class="explanations">
            <p>
                لطفا تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به صورت کاملا واضح و خوانا دقیقا مانند تصویر نمونه ارسال نمایید
                 دقت شود متن دست نوشته نبایستی تایپ شودهمچنین توجه داشته باشیدهنگام تهیه تصویر، متن دست نوشته و اطلاعات کارت ملی به صورت برعکس و آینه ای ثبت نشود
            </p>
            <div class="handwriting">
                <span> متن دست نوشته : </span>
                <p class="handwrite-text">
                   اینجانب (نام و نام خانوادگی) به کدملی(کد ملی) ضمن مطالعه و تایید قوانین استفاده از خدمات زیین کارنسی، متعهد میگردم که حساب کاربری و مدارک خود را به منظور خرید و فروش ارز های دیجیتال در اختیار سایر افراد قرار ندهم. 
                </p>
            </div>
            <form
                @submit.prevent="upload"
                @dragover.prevent
                @dragenter="hovered=!hovered"
                @dragleave="hovered=!hovered"
                @drop.stop.prevent="hovered=false;drop($event);"
                class="upload-spot">
                
                <span> محل آپلود تصویر </span>
                <div v-if="path" class="pisc">
                    <img class="cloud" :src="path" alt="">
                </div>
                <div v-else class="pics">
                    <input 
                        id="uploa-file" 
                        type="file"
                        @change="upload($event.target)"
                        :disabled="readonly"
                        v-if="!readonly"
                        >


                    <div class="examples">
                        <img src="../../../assets/selfie2.svg" alt="">
                        <img src="../../../assets/selfie1.svg" alt="">
                    </div>
                    <button class="submit"> آپلود تصویر </button>
                </div>

            </form>
        </div>

    </div>
</template>

<script>
    export default {
        name:'UploadPic',
        props:['data'],
        data() {
            return {
                path: '',
                loadedpicture: '',
                hovered: '',
                infos:{
                    firstName:'',
                    lastName:'',
                    birthDay:'',
                    nationalCode:'',
                    validationHelp1:'لطفا تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به صورت کاملا واضح و خوانا دقیقا مانند تصویر نمونه ارسال نمایید',
                    validationHelp2:' دقت شود متن دست نوشته نبایستی تایپ شودهمچنین توجه داشته باشیدهنگام تهیه تصویر، متن دست نوشته و اطلاعات کارت ملی به صورت برعکس و آینه ای ثبت نشود',
                    validationHandWrite2:'اینجانب (نام و نام خانوادگی) به کدملی(کد ملی) ضمن مطالعه و تایید قوانین استفاده از خدمات زیین کارنسی، متعهد میگردم که حساب کاربری و مدارک خود را به منظور خرید و فروش ارز های دیجیتال در اختیار سایر افراد قرار ندهم.',
                }
            }
        },
        computed: {
            readonly() {
                return ['AUTHORIZED', "INFORMATION_CHANGED"].includes(this.state.userInfo.authenticationType)
            }
        },
        watch: {
            'data.image'() {
                if (this.data.image)
                    if (typeof this.data.image === 'string')
                        this.path = this.data.image
            }
        },
        methods: {
            drop(e) {
                let dt = e.dataTransfer
                this.upload(dt)
            },
            upload(evt) {
                let file = evt.files[0]
                this.data.image = file
                let fileReader = new FileReader();
                fileReader.addEventListener("loadstart", (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                })
                fileReader.addEventListener("progress", (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                });
                fileReader.addEventListener('load', (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                    this.path = event.target.result
                });
                fileReader.readAsDataURL(file)
            },
            hover() {
                this.hovered = true
            }
        },
        mounted() {
            console.log(this.data.image);
        },
    }
</script>

<style lang="scss" scoped>
.cloud{
    width: 100%;
}
.submit{
    max-width: 140px;
}
.examples{
    display: flex;
    flex-direction: row;
    column-gap: 16px;
}
.pics{
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: center;
    padding: 24px;
    width: 100%;
    border: 2px dashed #888E95;
    border-radius: 12px;
    background: linear-gradient(180deg, rgba(219, 210, 188, 0.15) 0%, rgba(222, 214, 195, 0.41) 100%);
    input{
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}
.upload-spot{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
.handwriting{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    .handwrite-text{
        color: rgba(0, 17, 106, 1);
    }
}
.explanations{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
span{
    font-weight: 400;
    font-size: clamp(14px,2vw,18px);
}
.personal-info{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px;
    background: white;
    border-radius: 12px;
    width: 100%;
}
@media only screen and(max-width:500px){
    .examples{
        flex-direction: column;
        gap: 16px;
    }
}
</style>