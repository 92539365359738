<template>
    <div class="personal-info">
        <span> اطلاعات تماس </span>


        <div @click="blanckSpace.mobileNumber=true" class="ac-input">
            <label for="">
                <span :class="{'active-span':blanckSpace.mobileNumber || data.mobileNumber}"> شماره موبایل </span>
                <input @focus="blanckSpace.mobileNumber=true" @input="data.mobileNumber=$numberFilter(data.mobileNumber,11)" v-model="data.mobileNumber" type="text">
            </label>
        </div>



         <div @click="blanckSpace.telephone=true" class="ac-input">
            <label for="">
                <span :class="{'active-span':blanckSpace.telephone || data.telephone}">  تلفن ثابت  </span>
                <input @focus="blanckSpace.telephone=true" @input="data.telephone=$numberFilter(data.telephone,11)" v-model="data.telephone" type="text">
            </label>
        </div>


         <div @click="blanckSpace.address=true" class="ac-input">
            <label for="">
                <span :class="{'active-span':blanckSpace.address || data.address}"> آدرس  </span>
                <input @focus="blanckSpace.address=true" v-model="data.address" type="text">
            </label>
        </div>


    </div>
</template>

<script>
    export default {
        name:'PhoneInfo',
        props:['data'],
        data() {
            return {
                blanckSpace:{
                    mobileNumber:false,
                    telephone: false,
                    address: false,
                }
            }
        },
        created:function () {
            window.addEventListener("click", event => {
                if(!event.target.closest('.ac-input')){
                    Object.keys(this.blanckSpace).map(a => {
                                if(this.data[a] == ''){
                                    this.blanckSpace[a] = false
                                }
                    });
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
.ac-input{
    border-radius: 4px;
    // overflow: hidden;
    .bd{
        input{
            width: 100%;
        }
    }
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    width: 100%;
    .active-span{
    color: var(--primary-color);
    transform: translateY(-25px);
    }
    label{
        display: flex;
        flex-direction: row;
        background: var(--input-bg);
        padding: 8px 12px;
        border-radius: 4px;
        position: relative;
        input{
            background: transparent;
            width: 80%;
            z-index: 2;
        }
        span{
            transform: translateY(0px);
            font-weight: 400;
            font-size: clamp(12px,2vw,14px);
            transition: 500ms;
            position: absolute;
        }
    }
}
span{
    font-weight: 400;
    font-size: clamp(14px,2vw,18px);
}
.personal-info{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px;
    background: white;
    border-radius: 12px;
    width: 100%;
}
</style>