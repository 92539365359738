<template>
    <div class="personal-info">
        <div class="title-container">
            <span> اطلاعات بانکی </span>
            <!-- <div class="addcard">
                <img class="addcard-img" src="../../../assets/icons/addcard.svg" alt="">
                <span class="addcardbtn"> اضافه کردن کارت </span>
            </div> -->




            
        </div>
        <div class="card-container">
            <div @click="blanckSpace.cardNumber=true" class="ac-input">
                <label for="">
                    <span :class="{'active-span':blanckSpace.cardNumber}"> شماره کارت </span>
                    <input @input="dash" @focus="blanckSpace.cardNumber=true" v-model="newCard.cardNumber" type="text">
                </label>
            </div>
            <div @click="blanckSpace.shebaCode=true" class="ac-input">
                <label for="">
                    <span :class="{'active-span':blanckSpace.shebaCode}"> شماره شبا </span>
                    <input @input="newCard.shebaCode=$numberFilter(newCard.shebaCode,26)" @focus="blanckSpace.shebaCode=true" v-model="newCard.shebaCode" type="text">
                </label>
            </div>
            <button :disabled="disable" @click="addCard" class="submit addtocardsbtn"> {{disable ? disable :'افزودن کارت بانکی'}} </button>
        </div>






        <template v-for="(card,index) in data.bankAccounts">
        <div v-if="card.shebaCode && card.cardNumber" :key="index" class="card-container">
            <div class="ac-input">
                <label for="">
                    <span class='active-span'> شماره کارت </span>
                    <input disabled v-model="card.cardNumber" type="text">
                </label>
            </div>
            <div class="ac-input">
                <label for="">
                    <span class='active-span'> شماره شبا </span>
                    <input disabled v-model="card.shebaCode" type="text">
                </label>
            </div>
            <div v-if="data.bankAccounts.length > 1" @click="deleteCart(card)" class="delet">
                <inline-svg :src="require('../../../assets/icons/remove.svg')" alt="" />
                <span> حذف </span>
            </div>
        </div>
        </template>
    </div>
</template>

<script>
    export default {
        props:['data'],
        data() {
            return {
                newCard:{
                    shebaCode:'',
                    cardNumber:''
                },
                blanckSpace:{
                    shebaCode:'',
                    cardNumber:''
                },
                // cards:[...this.data.bankAccounts,...this.state.userInfo.bankAccounts],
        }
        },
        computed: {
            // cards(){
            //     let allCards
            //         allCards = [...this.data.bankAccounts,...this.state.userInfo.bankAccounts]
            //     return allCards
            // },
            disable(){
                let err
                    if(!this.newCard.cardNumber){
                        err = 'شماره کارت را وارد کنید'
                    }else if (this.newCard.cardNumber.length !== 19){
                        err = 'شماره کارت صحیح وارد کنید'
                    }else if(!this.newCard.shebaCode){
                        err = 'شماره شبا را وارد کنید'
                    } else{
                        err = false
                    }
                return err
            }
        },
        methods: {
            deleteCart(item){
                let indx = this.data.bankAccounts.indexOf(item)
                this.data.bankAccounts.splice(indx,1)
            },
            dash(){
                if(this.newCard.cardNumber){
                    this.newCard.cardNumber = this.$separator(this.newCard.cardNumber)
                    let lngth  = this.newCard.cardNumber.replace(/-/ig,'').length
                    if(lngth >= 16){
                            this.newCard.cardNumber=this.newCard.cardNumber.replace(/-/ig,'').slice(0,16);
                            this.newCard.cardNumber = this.$separator(this.newCard.cardNumber);
                    }
                }
            },
            addCard(){
                this.data.bankAccounts = [
                            {
                               shebaCode:this.newCard.shebaCode,
                               cardNumber:this.newCard.cardNumber.replace(/-/ig,''),
                            },...this.data.bankAccounts
                ]
                this.newCard = {
                    shebaCode:'',
                    cardNumber:''
                }
            }
        },




        created:function () {
            window.addEventListener("click", event => {
                if(!event.target.closest('.ac-input')){
                    Object.keys(this.blanckSpace).map(a => {
                                if(this.newCard[a] == ''){
                                    this.blanckSpace[a] = false
                                }
                    });
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
.delet{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    align-items: center;
    span{
        color: var(--red);
        font-size: clamp(14px,1vw,12px);
    }
    svg{
        fill: var(--red);
        width: 18px;
        height: 18px;
    }
}
.addtocardsbtn{
    width: 40%;
}
.addcard-img{
    width: 12px;
    height: 12px;
}
.addcardbtn{ 
    color: var(--primary-color);
    font-size: clamp(12px,1vw,14px);
}
.addcard{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
}
.card-container{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 12px;
    background: rgba(251, 251, 246, 1);
}
.ac-input{
    border-radius: 4px;
    // overflow: hidden;
    .bd{
        input{
            width: 100%;
        }
    }
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    width: 100%;
    .active-span{
    color: var(--primary-color);
    transform: translateY(-25px);
    }
    label{
        display: flex;
        flex-direction: row;
        background: var(--input-bg);
        padding: 8px 12px;
        border-radius: 4px;
        position: relative;
        input{
            background: transparent;
            width: 80%;
            z-index: 2;
        }
        span{
            transform: translateY(0px);
            font-weight: 400;
            font-size: clamp(12px,2vw,14px);
            transition: 500ms;
            position: absolute;
        }
    }
}
span{
    font-weight: 400;
    font-size: clamp(14px,2vw,18px);
}
.personal-info{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px;
    background: white;
    border-radius: 12px;
    width: 100%;
}
.title-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@media only screen and(max-width:500px){
    .submit{
        width: 100%;
    }
}
</style>