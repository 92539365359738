<template>
    <div class="personal-info">
        <span> اطلاعات شخصی </span>
        <div class="item-container">
            <div class="item">
                <p> نام و نام خانوادگی </p>
                <p> {{state.userInfo.firstName}} {{state.userInfo.lastName}} </p>
            </div>
            <div class="item">
                <p> کدملی </p>
                <p> {{state.userInfo.nationalCode}} </p>
            </div>
            <div class="item">
                <p> تاریخ تولد </p>
                <p> {{$G2J(state.userInfo.birthDay,'faDate')}} </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'ShowPersonalInfo',
        props:['data'],
        
    }
</script>

<style lang="scss" scoped>
.item-container{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.item{
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
}
.personal-info{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px;
    background: white;
    border-radius: 12px;
    width: 100%;
}
</style>